class HomeCategories extends Component {
    constructor(el) {
        super(el);
        this.sliderEl = this.$find('[data-category-slider]');

        this.items = this.$findAll('[data-cy="home-category"]');
        this.items && this.enhanceHover();

        this.slider = null;
        this.sliderEl && this.constructSlider();

        this.attachEvents();
    }

    constructSlider() {
        const perPageCount = getComputedStyle(this.sliderEl).getPropertyValue('--slidesPerPage');
        if (parseInt(perPageCount) < this.items.length) {
            if (!(this.slider instanceof SimpleSlider))
                this.slider = new SimpleSlider(this.sliderEl);
        } else if (this.slider) {
            this.slider.destroy();
            this.slider = null;
        }
    }

    enhanceHover() {
        this.items.forEach(item => {
            let isHovered = true;

            item.addEventListener('mouseenter', () => {
                isHovered = true;
                item.classList.add('is-hovered');
            });

            item.addEventListener('mouseleave', () => {
                isHovered = false;
                item.classList.remove('is-hovered');
                item.classList.add('is-fading');
            });

            item.addEventListener('transitionend', () => {
                !isHovered && item.classList.remove('is-fading');
            });
        })
    }

    attachEvents() {
        window.addEventListener('resize', () => this.constructSlider());
    }
}
